<template>
  <b-card>
    <div>
      <validation-observer ref="provider">
        <b-form>
          <b-row>
            <b-col md="9">
              <b-form-group label="Min sell" label-for="pg-min-amount_sell">
                <validation-provider #default="{ errors }" name="pg-min-amount_sell" rules="required">
                  <b-form-input
                    v-model.number="form.minAmountSell"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="min amount sell"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="9">
              <b-form-group label="Min buy" label-for="pg-min-amount_buy">
                <validation-provider #default="{ errors }" name="pg-min-amount_buy" rules="required">
                  <b-form-input
                    v-model.number="form.minAmountBuy"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="min amount buy"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="9">
              <b-form-group label="Max buy" label-for="pg-max-amount_buy">
                <validation-provider #default="{ errors }" name="pg-max-amount_buy" rules="required">
                  <b-form-input
                    v-model.number="form.maxAmountBuy"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="max amount sell"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="9">
              <b-form-group label="Max sell" label-for="pg-max-amount_sell">
                <validation-provider #default="{ errors }" name="pg-max-amount_sell" rules="required">
                  <b-form-input
                    v-model.number="form.maxAmountSell"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="max amount sell"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
            Edit
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox, BFormTextarea } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

// eslint-disable-next-line
import { required } from '@validations'
export default {
  name: 'ThresholdTransaction',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      form: {
        minAmountSell: 0,
        maxAmountSell: 0,
        minAmountBuy: 0,
        maxAmountBuy: 0,
      },
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchThreshold: 'pgFees/fetchThreshold',
      updateThreshold: 'pgFees/updateThreshold',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      this.getThreshold()
    },

    update() {
      const { minAmountSell, maxAmountSell, minAmountBuy, maxAmountBuy } = this.form

      const formData = {
        'pg-min-amount_sell': minAmountSell,
        'pg-max-amount_sell': maxAmountSell,
        'pg-min-amount_buy': minAmountBuy,
        'pg-max-amount_buy': maxAmountBuy,
      }

      this.waitRequest(() => {
        return this.updateThreshold({ form: formData })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Threshold is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
          })
          .catch(this.checkErrors)
      })
    },

    getThreshold() {
      this.waitRequest(() => {
        return this.fetchThreshold()
          .then(response => {
            const {
              'pg-min-amount_sell': minAmountSell,
              'pg-max-amount_sell': maxAmountSell,
              'pg-min-amount_buy': minAmountBuy,
              'pg-max-amount_buy': maxAmountBuy,
            } = response.data?.data

            this.form = { minAmountSell, maxAmountSell, minAmountBuy, maxAmountBuy }
          })
          .catch(this.checkErrors)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid || !this.form) return

      return this.update()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
