var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Min sell","label-for":"pg-min-amount_sell"}},[_c('validation-provider',{attrs:{"name":"pg-min-amount_sell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"min amount sell"},model:{value:(_vm.form.minAmountSell),callback:function ($$v) {_vm.$set(_vm.form, "minAmountSell", _vm._n($$v))},expression:"form.minAmountSell"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Min buy","label-for":"pg-min-amount_buy"}},[_c('validation-provider',{attrs:{"name":"pg-min-amount_buy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"min amount buy"},model:{value:(_vm.form.minAmountBuy),callback:function ($$v) {_vm.$set(_vm.form, "minAmountBuy", _vm._n($$v))},expression:"form.minAmountBuy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Max buy","label-for":"pg-max-amount_buy"}},[_c('validation-provider',{attrs:{"name":"pg-max-amount_buy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"max amount sell"},model:{value:(_vm.form.maxAmountBuy),callback:function ($$v) {_vm.$set(_vm.form, "maxAmountBuy", _vm._n($$v))},expression:"form.maxAmountBuy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Max sell","label-for":"pg-max-amount_sell"}},[_c('validation-provider',{attrs:{"name":"pg-max-amount_sell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"max amount sell"},model:{value:(_vm.form.maxAmountSell),callback:function ($$v) {_vm.$set(_vm.form, "maxAmountSell", _vm._n($$v))},expression:"form.maxAmountSell"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Edit ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }